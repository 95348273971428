@import '../variables';

.slick-dots {
  li {
    margin: 0;
    width: 15px;

    button {
      &:before {
        content: '';
        width: 6px;
        height: 6px;
        background: #E3E3E3;
        border-radius: 10px;
        opacity: 1;
        margin: 7px;
      }

      &:hover:before {
        background: $primary;
      }
    }

    &.slick-active button:before {
      background: $primary;
    }
  }
}
