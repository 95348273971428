$navbar-padding-x: 2rem;
$grid-row-columns: 0;
$navbar-height: 70px;

$sizes: (
  520p: 520px,
  380p: 380px,
  230p: 230px,
  200p: 200px,
  180p: 180px,
  128p: 128px,
  112p: 112px,
  100p: 100px,
  96p: 96px,
  90p: 90px,
  72p: 72px,
  64p: 64px,
  60p: 60px,
  56p: 56px,
  48p: 48px,
  42p: 42px,
  32p: 32px,
  30p: 30px,
  26p: 26px,
  10p: 10px,
  8p: 8px,
  5p: 5px,
  3p: 3px
);

$size-sm: 576px;
$size-md: 768px;
$size-lg: 992px;
$size-xl: 1200px;

// Colors
// Naming convention:
// http://chir.ag/projects/name-that-color
$flint: #6d6966;
$pale-sky: #6e777f;
$iron: #ccd1d6;
$regent-gray: #919aa3;
$mako: #42444a;
$shark: #2f3034;
$aqua-haze: #edf1f5;
$alto: #d8d8d8;
$tarawera: #072a44;
$peach: #ffe8b1;
$desert-white: #F1F1EF;
$sea-shell: #F7F6F5;
$white: #FFFFFF;
$primary: #698BA7;
$danger: #F25C5C;
$hawkes-blue: #d9e3fc;

// Theme colors
$theme-colors: (
    'primary': $primary,
    'danger': $danger,
    'shark': $shark,
    'flint': $flint,
    'iron': $iron,
    'regent-gray': $regent-gray,
    'mako': $mako,
    'aqua-haze': $aqua-haze,
    'pale-sky': $pale-sky,
    'tarawera': $tarawera,
    'desert-white': $desert-white,
    'sea-shell': $sea-shell,
    'white': $white,
);

// Shadows
$box-shadow-sm: 0 0.125rem 0.25rem rgba($tarawera, 0.075);
$box-shadow: 0 0.5rem 1rem rgba($tarawera, 0.1);
$box-shadow-lg: 0 1rem 3rem rgba($tarawera, 0.175);

$fixed-bottom-bar-height: 95px;
