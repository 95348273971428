.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: $card-height;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: $card-spacer-x;
  color: $card-color;
}

.card-title {
  margin-bottom: $card-spacer-y;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-header {
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  color: $card-cap-color;
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }
}

.card-footer {
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }
}

.accordion {
  > .card {
    overflow: hidden;

    &:not(:last-of-type) {
      border-bottom: 0;
      @include border-bottom-radius(0);
    }

    &:not(:first-of-type) {
      @include border-top-radius(0);
    }

    > .card-header {
      @include border-radius(0);
      margin-bottom: -$card-border-width;
    }
  }
}
