@import '../styles/variables';

.cookieBanner {
  position: fixed;
  bottom: 0;
  z-index: 20;
  background: $aqua-haze;
  min-height: 100px;
  font-weight: 400;
  font-size: 13px;
  padding: 1.5rem;
  line-height: 18px;
  color: $tarawera;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 5px 1rem grey;

  @media only screen and (min-width: $size-md) {
    bottom: 0.5rem;
    box-shadow: 0 0.5rem 1rem grey;
    border-radius: 0.25rem;
  }
}
