@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$colors: map_remove(
        $colors,
        'blue',
        'indigo',
        'purple',
        'pink',
        'red',
        'orange',
        'yellow',
        'green',
        'teal',
        'cyan',
        'white',
        'gray',
        'gray-dark'
);

$theme-colors: map_remove(
  $theme-colors,
  'info',
  'success',
  'warning',
  'secondary',
  'dark'
);

@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
@import './bootstrap/type';
@import '~bootstrap/scss/grid';
@import './bootstrap/forms';
@import './bootstrap/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import './bootstrap/nav';
@import './bootstrap/navbar';
@import './bootstrap/card';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/utilities';


@media only screen and (min-width: $size-lg) {
  .navbar {
    height: $navbar-height;
    padding-right: $navbar-padding-x !important;
  }
}
