@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./variables";

@layer base {
  p {
    @apply t-mb-4;
  }

  img {
    @apply t-inline;
    @apply t-max-w-none;
    @apply t-h-auto;
  }
}

@import "./icons";
@import "./atoms";
@import "./bootstrap";
@import "./global";
@import "./daypicker";
