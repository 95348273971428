@import '../../../../styles/variables';

.TrustPilotButton {
  a.btn {
    border-color: $mako;

    &:hover {
      border-color: $mako !important;
    }

    &.dark {
      color: $mako;
    }

    &.light {
      color: white;
    }

    &.btn-default {
      div {
        align-items: center;

        span {
          margin-top: 2px;
        }
      }
    }

    &.btn-lg {
      padding-left: 2rem !important;
      padding-right: 2rem !important;
    }

    div {
      display: flex;
      span {
        max-height: 20px;
      }

      img {
        margin-left: 10px;
        height: 20px;
        max-width: 70px;
      }
    }
  }
}
