.Footer {
  .link-item {
    min-width: 80px;
  }

  .social-icons {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      margin-left: 12px;
      background-position: center center;
      background-repeat: no-repeat;
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-radius: 30px;
      opacity: 0.5;
      transition: all 0.1s ease;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        opacity: 1;
      }

      &.facebook:hover {
        background-color: #3B5998;
        border-color: #3B5998;
      }

      &.instagram:hover {
        background-color: #3F729B;
        border-color: #3F729B;
      }

      &.linkedin:hover {
        background-color: #007FB1;
        border-color: #007FB1;
      }

      &.youtube:hover {
        background-color: #FF0000;
        border-color: #FF0000;
      }
    }
  }

  .divider-line {
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    opacity: 0.05;
  }
}
