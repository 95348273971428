@import 'variables';

.DayPicker {
  font-size: 15px;
  width: 100%;

  &,
  .DayPicker-Month,
  .DayPicker-wrapper {
    outline: none;
    width: 100%;
  }

  .DayPicker-Month {
    margin-left: 0;
  }

  .DayPicker-Day {
    outline: none;
    cursor: pointer !important;
    @extend .text-pale-sky;
    background: none !important;
    padding: 0;

    span {
      width: 30px;
      line-height: 30px;
      border-radius: 20px;
      display: inline-block;
      margin-bottom: 5px;
    }

    &:hover {
      span {
        background-color: #F0F8FF;
      }
    }
  }

  .DayPicker-Day--has-times {
    span {
      background-color: $peach !important;
    }
  }

  .DayPicker-Day--event, .DayPicker-Day--has-session {
    span {
      background-color: #27b2b254 !important;
    }
  }

  .DayPicker-Day--selected {
    span {
      color: #fff !important;
      background-color: $primary !important;
    }
  }

  .DayPicker-Day--disabled {
    opacity: 0.5;
    cursor: default !important;
  }
}
