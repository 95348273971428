@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?jgwqnh');
  src: url('fonts/icomoon.eot?jgwqnh#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?jgwqnh') format('truetype'),
  url('fonts/icomoon.woff?jgwqnh') format('woff'),
  url('fonts/icomoon.svg?jgwqnh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-question-circle-o:before {
  content: "\e906";
}

.icon-chevron-down:before {
  content: "\e900";
}

.icon-chevron-up:before {
  content: "\e90e";
}

.icon-arrow-down:before {
  content: "\e905";
}

.icon-info:before {
  content: "\e903";
}

.icon-clock:before {
  content: "\e910";
}

.icon-checkmark:before {
  content: "\ea10";
}

.icon-lock_outline:before {
  content: "\e90f";
}

.icon-eye-blocked:before {
  content: "\e9d1";
}

.icon-eye:before {
  content: "\e9ce";
}

.icon-camera:before {
  content: "\e901";
}

.icon-close:before {
  content: "\e902";
}

.icon-facebook:before {
  content: "\e904";
}

.icon-inbox:before {
  content: "\e907";
}

.icon-arrow-next:before {
  content: "\e90b";
}

.icon-arrow-prev:before {
  content: "\e90d";
}
