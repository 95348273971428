h1, h2, h3, h4, h5 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1 {
  @include font-size($h1-font-size);
}

h2 {
  @include font-size($h2-font-size);
}

h3 {
  @include font-size($h3-font-size);
}

h4 {
  @include font-size($h4-font-size);
}

h5 {
  @include font-size($h5-font-size);
}

small {
  @include font-size($small-font-size);
  font-weight: $font-weight-normal;
}
