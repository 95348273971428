@import '../styles/variables';

.navBar {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.06), 0 0 2px rgba(0, 0, 0, 0.07);
  height: $navbar-height;

  @media only screen and (max-width: $size-md - 1) {
    padding: 1rem;
  }

  @media only screen and (max-width: 370px) {
    .navbar-toggler {
      padding-right: 0;
    }

    img {
      width: 90px;
      height: auto;
    }
  }

  button.login-button.btn {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .menu-item-underline {
    border-bottom: 1px solid $shark;
  }
}
