@import "variables";

.font-f-libre {
  font-family: 'Libre Baskerville', serif;
}

.lh-1-2 {
  line-height: 1.2;
}

.lh-1-6 {
  line-height: 1.6;
}

.mw-250 {
  max-width: 250px;
  margin: 0 auto;
}

.mw-300 {
  max-width: 300px;
  margin: 0 auto;
}

.mw-350 {
  max-width: 350px;
  margin: 0 auto;
}

.mw-400 {
  max-width: 400px !important;
  margin: 0 auto;
}

.mw-600 {
  max-width: 600px !important;
  margin: 0 auto;
}

.mw-750 {
  max-width: 750px !important;
  margin: 0 auto;
}

.mw-900 {
  max-width: 900px !important;
  margin: 0 auto;
}

.mw-930 {
  max-width: 930px !important;
  margin: 0 auto;
}

.border-2 {
  border-width: 2px !important;
}

.fs-6 {
  font-size: 6px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.fs-32 {
  font-size: 32px;
}

.fs-36 {
  font-size: 36px;
}

.fs-48 {
  font-size: 48px;
}

.fs-72 {
  font-size: 72px;
}

.zi-1 {
  z-index: 1;
}

.zi-2 {
  z-index: 2;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.mb-1-rem {
  margin-bottom: 1rem !important;
}

.mt-1-rem {
  margin-top: 1rem !important;
}

.my-1-rem {
  @extend .mb-1-rem;
  @extend .mt-1-rem;
}

.mb-1-5-rem {
  margin-bottom: 1.5rem !important;
}

.mt-1-5-rem {
  margin-top: 1.5rem !important;
}

.mb-2-rem {
  margin-bottom: 2rem !important;
}

.mt-2-rem {
  margin-top: 2rem !important;
}

.mb-2-5-rem {
  margin-bottom: 2.5rem !important;
}

.mb-3-rem {
  margin-bottom: 3rem !important;
}

.mt-3-rem {
  margin-top: 3rem !important;
}

.my-5-rem {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-8-rem {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.mb-5-rem {
  margin-bottom: 5rem !important;
}

.mt-5-rem {
  margin-top: 5rem !important;
}

.mb-8-rem {
  margin-bottom: 8rem !important;
}

.mt-8-rem {
  margin-top: 8rem !important;
}

.px-1-rem {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-2-rem {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-3-rem {
  padding-left: 3rem;
  padding-right: 3rem;
}

.pt-1-rem {
  padding-top: 1rem;
}

.py-1-rem {
  @extend .pt-1-rem;
  padding-bottom: 1rem;
}

.pb-1-5-rem {
  padding-bottom: 1.5rem;
}

.py-1-5-rem {
  padding-top: 1.5rem;
  @extend .pb-1-5-rem;
}

.py-2-rem {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.pb-2-5-rem {
  padding-bottom: 2.5rem !important;
}

.pt-2-5-rem {
  padding-top: 2.5rem !important;
}

.py-2-5-rem {
  @extend .pb-2-5-rem;
  @extend .pt-2-5-rem;
}

.pt-3-rem {
  padding-top: 3rem !important;
}

.pb-3-rem {
  padding-bottom: 3rem !important;
}

.py-3-rem {
  @extend .pt-3-rem;
  @extend .pb-3-rem;
}

.pt-5-rem {
  padding-top: 5rem !important;
}

.pb-5-rem {
  padding-bottom: 5rem !important;
}

.py-5-rem {
  @extend .pt-5-rem;
  @extend .pb-5-rem;
}

.pr-5-rem {
  padding-right: 5rem !important;
}

//sm
@media (min-width: $size-sm) {

}

//md
@media (min-width: $size-md) {
  .px-md-5-rem {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .mb-md-5-rem {
    margin-bottom: 5rem !important;
  }

  .mt-md-3-rem {
    margin-top: 3rem !important;
  }
}

//lg
@media (min-width: $size-lg) {
  .mb-lg-1-rem {
    margin-bottom: 1rem !important;
  }

  .mb-lg-1-5-rem {
    margin-bottom: 1.5rem !important;
  }

  .mt-lg-3-rem {
    margin-top: 3rem !important;
  }

  .mb-lg-3-rem {
    margin-bottom: 3rem !important;
  }

  .mb-lg-5-rem {
    margin-bottom: 5rem !important;
  }

  .mt-lg-5-rem {
    margin-top: 5rem !important;
  }

  .my-lg-5-rem {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .mb-lg-8-rem {
    margin-bottom: 8rem !important;
  }

  .mt-lg-8-rem {
    margin-top: 8rem !important;
  }

  .my-lg-8-rem {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .pb-lg-8-rem {
    padding-bottom: 8rem !important;
  }

  .pt-lg-8-rem {
    padding-top: 8rem !important;
  }

  .py-lg-8-rem {
    @extend .pt-lg-8-rem;
    @extend .pb-lg-8-rem;
  }

  .pr-lg-4-rem {
    padding-right: 4rem !important;
  }

  .pr-lg-5-rem {
    padding-right: 5rem !important;
  }

  .pb-lg-5-rem {
    padding-bottom: 5rem !important;
  }

  .pt-lg-5-rem {
    padding-top: 5rem !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;;
  }

  .px-lg-1-rem {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .bg-lg-white {
    background: white !important;
  }
}

//xl
@media (min-width: $size-xl) {

}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.rounded-8p {
  border-radius: 8px !important;
}

.rounded-bottom-8p {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.rounded-1-rem {
  border-radius: 1rem !important;
}

.td-line-through {
  text-decoration: line-through;
}

.bg-hb-to-dw-bl-tr {
  background: linear-gradient(12.92deg, $hawkes-blue 2.38%, $desert-white 97.25%)
}
