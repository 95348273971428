@import "../../styles/variables";

.mobileMenu {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background: white;
  z-index: 200;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .menuBody, .menuFooter {
    padding: 1rem;
  }

  .menuHeader {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.5rem 1rem 1rem;

    .nameWrap {
      padding-left: 1rem;
      display: flex;
      align-items: center;
    }

    .dot {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background: $primary;
      margin-right: 12px;
    }

    .fullName {
      color: $shark;
      font-weight: 500;
    }

    .closeButton {
      font-size: 2.5rem;
      color: $shark;
    }
  }

  .menuBody {
    flex-grow: 1;

    a {
      width: fit-content;

      &:hover {
        opacity: 0.8;
      }
    }

    &.unauthenticated {
      height: 100%;
    }

    .mainMenu, .authenticatedMenu {
      font-weight: 500;
      padding: 1rem;
    }

    .mainMenu {;
      line-height: 2rem;
    }

    .menuBorder {
      opacity: 0.3;
      border-bottom: 1px solid $shark;
      margin-left: -1rem;
      margin-right: -1rem;
    }

    .authenticatedMenu {
      line-height: 2.5rem;
    }
  }

  .menuFooter {
    bottom: 0;
    width: 100%;
    padding: 0 2rem;

    .authenticationButtons {
      margin-bottom: 3rem;
    }

    .rightsText {
      left: 1rem;
      color: #FFFFFF;
      opacity: 0.5;
      font-size: 12px;
    }
  }
}
