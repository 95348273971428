@import './variables';

*:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

textarea {
  &.resize-none {
    resize: none;
  }
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type=checkbox]{
  -webkit-appearance: revert;
  -moz-appearance: revert;
  appearance: auto;
}

html,
body {
  min-height: 100%;
  height: 100%;
}

body {
  @media (min-width: $size-md) {
    overscroll-behavior: none;
  }

  background-color: $sea-shell;
  overflow-x: hidden;

  @media only screen and (min-width: $size-lg) {
    overflow-y: scroll;
  }

  margin: 0;
}

.btn {
  &.rounded {
    border-radius: 30px !important;
  }
}

button,
input {
  outline: none !important;
}

.btn {
  border-width: 2px;

  &:focus {
    box-shadow: none !important;
  }

  &.big {
    font-size: 16px;
  }

  &.btn-link.inline {
    padding: 0 !important;
  }
}

#root {
  @extend .d-flex;
  @extend .flex-column;
  min-height: 100vh;
}

.custom-control-label::before {
  border-width: 2px;
  border-color: $iron;
}

.therapist-card {
  border: 1px solid white;

  transition: 200ms;
}

@media only screen and (max-width: 961px) {
  .therapist-card {
    border: 1px solid hsla(26, 3%, 41%, 0.09);
  }
}

.navbar .dropdown-menu {
  right: 0;
  left: auto;
}

@media only screen and (max-width: 767px) {
  .menu {
    .dropdown-menu {
      display: block;
    }

    .dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }
}

.btn {
  font-weight: 700;
  font-size: 12px;
}

.btn:not(.btn-sm, .btn-lg) {
  @extend .py-2;
  @extend .px-3;
}

.btn.btn-lg {
  font-size: 16px;
  @extend .py-3;
  @extend .px-5;
}

.btn.btn-sm {
  @extend .py-1;
  @extend .px-2;
}

.btn.btn-outline-mako,
.btn.btn-outline-primary,
.btn.btn-outline-danger {
  @extend .border-2;
}

h1,
h2,
h3,
h4 {
  @extend .fw-700;
}

.active-sidebar-item {
  .active-sidebar-marker {
    display: block !important;
  }
}

.container-loading {
  position: relative;
  min-height: 100px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7) url(/images/loading-primary.svg) center center no-repeat;
  }
}

.container-loading-l {
  @extend .container-loading;

  &:after {
    background-size: 60px;
    background-color: transparent;
  }
}

.loading-full-screen {
  @extend .container-loading;

  position: initial;

  &:after {
    background: rgba(247, 246, 245, 0.7) url(/images/loading-primary.svg) center center no-repeat;
    z-index: 101;
    position: fixed;
  }
}

.session-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.flex-basis-0 {
  flex-basis: 0 !important;
}

a:not([href]) {
  text-decoration: underline;
  cursor: pointer;
}

a.remove-link {
  text-decoration: none;
  cursor: default;

  &:hover {
    text-decoration: none;
  }
}

.underline {
  text-decoration: underline !important;
}

.c-pointer {
  cursor: pointer !important;
}

.alert-small {
  font-size: 12px;

  &.alert-dismissible {
    .close {
      padding: 0.5rem 0.75rem;
    }
  }
}

.next-button {
  min-width: 150px;
}

.terms-link {
  white-space: nowrap;
}

.valid-feedback {
  color: $primary;
}

.form-control.is-valid {
  border-color: $primary;
  background-image: url(/images/valid-form-icon.svg);
}

.ke-video-container {
  iframe {
    width: 100% !important;
  }
}

.modal-close-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  background: url(/images/ico-close-primary.svg) center no-repeat;
  background-size: 14px;
  cursor: pointer;
}

@media (max-width: $size-md) {
  .light.btn-primary:active {
    color: $pale-sky !important;;
    background-color: #ffffff !important;
    border: 2px solid #FFFFFF !important;
  }
}

.badge-aqua-haze-padded {
  background: $aqua-haze;
  padding: 8px 20px;
  font-size: 13px;
  color: $pale-sky;
  margin: 0 8px 10px 0;
  font-weight: 500;
}

.modal-content {
  border-radius: 1rem;
  border: 0;

  > .container-loading::after {
    border-radius: 1rem;
  }
}

.full-height-container {
  min-height: calc(100vh - #{$navbar-height});
}

.grad-test {
  background: linear-gradient(12.92deg, #D9E3FC 2.38%, #F1F1EF 97.25%);
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
